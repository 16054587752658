import React from 'react'
import { connect } from 'react-redux'
import Dialog from '../tgDialogMigrate'
import { toast } from 'react-toastify'
import fetchPonyfill from 'fetch-ponyfill'
import {
  generateRandomId,
  getDisplayName,
  reportError,
  reportMessage,
  trackCurrentModal
} from '../../lib/utils'

import styled from 'styled-components'
const { fetch } = fetchPonyfill()

const StyledTGContactUs = styled.section`
  .contactWrapper {
    width: 360px;
    min-height: 505px;
    height: auto;
    max-height: 600px;
  }

  h2 {
    text-align: center;
    padding: 15px;
    position: relative;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 27px;
    font-weight: bold;
    letter-spacing: 0.1px;
    color: #063f59;
    border-bottom: 3px solid #f0f3f7;
  }

  .modalCloseButton {
    display: flex;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 5px;
    color: #a1a3a6;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
  }

  .contactForm {
    padding: 20px 40px;
  }

  label {
    display: block;
    font-size: 13px;
    letter-spacing: 0.1px;
    color: #063f59;
    padding-bottom: 5px;
  }

  input {
    display: block;
    padding: 5px 15px;
    background-color: #fff;
    width: 100%;
    border: solid 1px #d5d5d5;
    border-radius: 10px;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 15px;
    color: #063f59;
    height: 40px;
  }

  textarea {
    display: block;
    padding: 10px 20px;
    background-color: #fff;
    width: 100%;
    border: solid 1px #d5d5d5;
    border-radius: 10px;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 15px;
    color: #063f59;
    height: 106px;
    resize: none;
    margin-bottom: 40px;
  }

  input:focus,
  textarea:focus {
    border: 1px solid #0facf3;
  }

  .inputWrapper input,
  .inputWrapper select {
    margin-bottom: 15px;
  }

  .optionsSelectContainer {
    position: relative;
    display: block;
    width: 100%;

    &:after {
      position: absolute;
      content: '';
      top: calc(50% - 3px);
      right: 10px;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-color: #626262 transparent transparent transparent;
    }
  }

  select {
    appearance: none;
    display: block;
    height: 48px;
    width: 100%;
    background: none;
    font-size: 16px;
    font-family: gotham-rounded-book, sans-serif;
    color: #797979;
    padding: 5px 25px 5px 15px;
    border: solid 1px #d5d5d5;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:-ms-expand {
      display: none;
    }
  }

  button {
    width: 100%;
    font-family: gotham-rounded-book, sans-serif;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    margin: 0 0 15px 0;
    border-radius: 13px;
    background-color: #0facf3;
    border: none;
  }

  .contactLoading {
    width: 30px;
    height: 30px;
  }

  .emphasis {
    color: #0facf3;
    font-weight: bold;
    white-space: nowrap;
  }

  @media screen and (max-width: 500px) {
    .contactWrapper {
      width: 100%;
      height: auto;
    }
  }

  @media screen and (max-width: 400px) {
    .contactForm {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`

const contactUsOptions = [
  {
    option: "Poser une question à l'équipe",
    value: '0'
  },
  {
    option: 'Demande de suppression de compte',
    value: '1'
  }
  // {
  //   option: 'Demande de support',
  //   value: '2'
  // }
]

class TgContactUs extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: props.user ? props.user.email : '',
      name: props.user ? getDisplayName(props.user) : '',
      option: '0',
      message: '',
      fullScreen: false,
      contactPending: false
    }
  }

  resetState = () => {
    const defaultState = {
      email: this.props.user ? this.props.user.email : '',
      name: this.props.user ? getDisplayName(this.props.user) : '',
      message: '',
      contactPending: false
    }

    this.setState(defaultState)
  }

  onWidthChange = (mq) => {
    if (mq.matches) {
      this.setState({ fullScreen: true })
    } else {
      this.setState({ fullScreen: false })
    }
  }

  updateFullscreenStatus = () => {
    if (matchMedia) {
      const mq = window.matchMedia('(max-width: 500px)')
      mq.addListener(this.onWidthChange)
      this.onWidthChange(mq)
    }
  }

  deregisterFullScreenStatus = () => {
    if (matchMedia) {
      const mq = window.matchMedia('(max-width: 500px)')
      mq.removeListener(this.onWidthChange)
    }
  }

  notifyErrorOccured = (errorName) => {
    let message
    toast.dismiss()
    switch (errorName) {
      case 'MissingRequiredArguments':
        message = 'Tous les champs sont obligatoires.'
        break
      default:
        message =
          'Une erreur inconnue est survenue, veuillez réessayer plus tard!'
        break
    }

    return toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      draggable: false,
      bodyClassName: 'notifyError',
      pauseOnFocusLoss: false
    })
  }

  handleChange = (event) => {
    const newState = Object.assign({}, this.state)
    newState[event.target.name] = event.target.value
    this.setState(newState)
  }

  notifyEmailSent() {
    return toast.success('Message envoyé !', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      draggable: false,
      bodyClassName: 'notifySuccess',
      pauseOnFocusLoss: false
    })
  }

  handleSubmit = async (event) => {
    if (this.state.contactPending) {
      return
    }
    const { email, name, option, message } = this.state
    const optionMessage = contactUsOptions.find(
      ({ value }) => value === option
    )?.option
    event.preventDefault()
    this.setState({ contactPending: true })
    setTimeout(async () => {
      try {
        const response = await fetch(
          `${window.location.origin}/api/landing/contact-us`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'x-tgth-trace-id': generateRandomId()
            },
            body: JSON.stringify({
              email,
              name,
              message,
              option: optionMessage
            })
          }
        )

        const body = await response.json()

        if (response.ok) {
          console.log('Message submitted for email: ' + this.state.email)
          this.notifyEmailSent()
          this.resetState()
          this.props.handleModalClose()
        } else {
          console.error('Problem while submitting the message')
          reportMessage('Problem while submitting the message: ' + body.name)
          this.notifyErrorOccured(body?.name)
          this.setState({ contactPending: false })
        }
      } catch (e) {
        reportError(e)
        this.setState({ contactPending: false })
        this.notifyErrorOccured()
        console.error('Problem while submitting the message')
      }
    }, 500)
  }

  componentDidMount() {
    trackCurrentModal('contact-us')
    this.updateFullscreenStatus()
  }

  componentWillUnmount() {
    this.deregisterFullScreenStatus()
  }

  render() {
    return (
      <Dialog
        fullScreen={this.state.fullScreen}
        open={this.props.openModal}
        onClose={this.props.handleModalClose}
        aria-labelledby="responsive-dialog-title"
        disableEscapeKeyDown
        disableBackdropClick
      >
        <StyledTGContactUs>
          <div className="contactWrapper">
            <h2>
              Nous contacter
              <a
                className="modalCloseButton"
                onClick={this.props.handleModalClose}
              >
                X
              </a>
            </h2>
            <form className="contactForm" onSubmit={this.handleSubmit}>
              <div className="inputWrapper">
                <label htmlFor="contactEmail">Email</label>
                <input
                  type="email"
                  name="email"
                  id="contactEmail"
                  required
                  value={this.state.email}
                  onChange={this.handleChange}
                  readOnly={this.props.user}
                />
              </div>
              <div className="inputWrapper">
                <label htmlFor="contactName">Nom</label>
                <input
                  type="text"
                  name="name"
                  id="contactName"
                  required
                  value={this.state.name}
                  onChange={this.handleChange}
                  readOnly={this.props.user}
                />
              </div>
              {
                <div className="inputWrapper optionsSelectContainer">
                  <select
                    value={this.state.option}
                    name="option"
                    onChange={this.handleChange}
                    className="contacUsOption"
                    id="option"
                  >
                    {contactUsOptions.map(({ option, value }, index) => (
                      <option key={index} value={value}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              }
              <div className="messageWrapper">
                <div className="inputWrapper">
                  <label htmlFor="contactMessage">Message</label>
                  <textarea
                    name="message"
                    id="contactMessage"
                    required
                    value={this.state.message}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <button className="submitButton hvr-forward" type="submit">
                {!this.state.contactPending && <span>Envoyer</span>}
                {this.state.contactPending && (
                  <img
                    className="contactLoading"
                    src="/static/img/loading.svg"
                    alt="loading"
                  />
                )}
              </button>
            </form>
          </div>
        </StyledTGContactUs>
      </Dialog>
    )
  }
}

TgContactUs.propTypes = {}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    ...ownProps
  }
}

const mapDispatchToProps = (dispatch, getState) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TgContactUs)
