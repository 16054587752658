import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Link from 'next/link'
import TgNotifications from '../tgNotifications/tgNotifications'
import { clearNotification } from '../../lib/notification'
import {
  fetchNotifications,
  loadNotifications
} from '../tgNotifications/actions'
import { toast } from 'react-toastify'

const StyledTgNotificationsPreview = styled.section`
  .notificationsContainer {
    padding: 5px 15px;
  }

  .fetching {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .loading {
    width: 40px;
    height: 40px;
    margin: 30px;
  }

  .seeMore {
    padding: 11px;
    text-align: center;
    cursor: pointer;
    color: #0facf3;
    font-size: 14px;
    border-top: 3px solid #f0f3f7;
    display: block;
  }
`

class TgNotificationsPreview extends Component {
  componentDidMount() {
    const skip = 0
    const limit = this.props.notifications
      ? this.props.notifications.length
      : 10
    // If there's no notification (initialState is null), it fetches them
    if (!this.props.notifications && !this.props.fetchNotificationPending) {
      this.props.fetchNotifications(null, skip, limit)
    }
  }

  notifyErrorOccured = (errorName) => {
    let message
    toast.dismiss()
    switch (errorName) {
      case 'NotificationException':
        message =
          "Erreur lors de l'affichage des notifications, veuillez réessayer plus tard!"
        break
      default:
        message =
          "Erreur lors de l'affichage des notifications, veuillez réessayer plus tard!"
        break
    }

    return toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      draggable: false,
      bodyClassName: 'notifyError',
      pauseOnFocusLoss: false
    })
  }

  handleClearNotification = async (notificationId) => {
    const skip = 0
    const limit = this.props.notifications.length
    try {
      const response = await clearNotification(
        notificationId,
        skip,
        limit,
        null
      )
      this.props.loadNotifications(
        response.notifications,
        response.unreadCounter
      )
    } catch (error) {
      this.notifyErrorOccured(error.name)
      console.error('handleClearNotification ERROR:', error)
    }
  }

  render() {
    const {
      fetchNotificationPending,
      fetchNotificationSucceeded,
      notifications
    } = this.props

    const isNotification =
      notifications && notifications.length > 0 ? true : false

    return (
      <StyledTgNotificationsPreview>
        {fetchNotificationSucceeded && (
          <>
            <div className="notificationsContainer">
              <TgNotifications
                onClearNotification={this.handleClearNotification}
                preview
              />
            </div>
            {isNotification && (
              <Link href="/notifications">
                <a className="seeMore">Voir plus</a>
              </Link>
            )}
          </>
        )}
        {fetchNotificationPending && (
          <div className="fetching">
            <img
              className="loading"
              src="/static/img/loading-blue.svg"
              alt="loading"
            />
          </div>
        )}
      </StyledTgNotificationsPreview>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    ...ownProps
  }
}

const mapDispatchToProps = (dispatch, getState) => {
  return {
    fetchNotifications: (req, skip, limit) => {
      dispatch(fetchNotifications(req, skip, limit))
    },
    loadNotifications: (notifications, unreadCounter) => {
      dispatch(loadNotifications(notifications, unreadCounter))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TgNotificationsPreview)
