import React from 'react'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHome,
  faUser,
  faPiggyBank,
  faQuestionCircle,
  faEnvelope,
  faAngleRight,
  faBell,
  faDoorOpen,
  faSignInAlt,
  faPowerOff,
  faTable
} from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { connect } from 'react-redux'

const StyledTgDrawerContent = styled.section`
  .asideMenuContentWrapper {
    width: 300px;
    height: 100%;
    padding-top: 75px;
  }

  .permanentWrapper {
    z-index: 1;
    padding-top: 30px;
  }

  .linkItem {
    padding: 5px 30px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 50px;
    cursor: pointer;
    color: #6c6c6c; //default value for the arrow right color
  }

  .linkItemActive {
    border-left: 5px solid #0facf3;
    padding-left: 25px;
    color: #0facf3;
  }

  .iconWrapper {
    width: 21px;
    height: 21px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #0facf3;
  }

  .notificationWrapper {
    position: relative;
  }

  .notificationsLength {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #f44336;
    text-align: center;
    line-height: 20px;
    display: block;
    color: #fff;
    font-family: gotham-rounded-book, sans-serif;
    font-size: 13px;
    right: -13px;
    top: calc(50% - 18px);
    position: absolute;
  }

  .linkContent {
    font-family: gotham-rounded-book, sans-serif;
    font-size: 17px;
    line-height: 1.59;
    letter-spacing: normal;
    color: #063f59;
    flex-grow: 1;
    padding: 0 10px 0 25px;
  }

  .contactUsItem {
    cursor: pointer;
  }

  .linkItemActive .linkContent {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: normal;
    color: #0facf3;
  }

  .createPiggy {
    width: 240px;
    height: 50px;
    line-height: 50px;
    border-radius: 35px;
    background-image: linear-gradient(to bottom, #fad961, #f76b1c);
    color: #fff;
    font-size: 16px;
    font-family: gotham-rounded-book, sans-serif;
    font-weight: bold;
    letter-spacing: 0.3px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    box-shadow: 4px 2px 4px 0 rgba(135, 135, 135, 0.5);
    margin: 30px auto 30px auto;
  }

  .logoutLoading {
    width: 30px;
    height: 30px;
  }
`

class TgDrawerContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      homeClass: 'linkItem',
      profileClass: 'linkItem',
      dashboardClass: 'linkItem',
      piggybanksClass: 'linkItem',
      howItWorksClass: 'linkItem',
      contactUsClass: 'linkItem',
      notificationsClass: 'linkItem',
      handleCloseDrawer:
        typeof props.handleCloseDrawer === 'function'
          ? props.handleCloseDrawer
          : () => {}
    }
  }

  updateLinksClasses = () => {
    if (window && window.location.pathname) {
      let currentState = Object.assign({}, this.state)
      currentState = {
        ...currentState,
        homeClass: `linkItem ${
          window &&
          (window.location.pathname === '/' ||
            window.location.pathname === '/home')
            ? 'linkItemActive'
            : 'hvr-icon-forward'
        } `,
        profileClass: `linkItem ${
          window && window.location.pathname.startsWith('/profile')
            ? 'linkItemActive'
            : 'hvr-icon-forward'
        }`,
        dashboardClass: `linkItem ${
          window && window.location.pathname.startsWith('/dashboard')
            ? 'linkItemActive'
            : 'hvr-icon-forward'
        }`,
        piggybanksClass: `linkItem ${
          window && window.location.pathname.startsWith('/list-piggys')
            ? 'linkItemActive'
            : 'hvr-icon-forward'
        }`,
        howItWorksClass: `linkItem ${
          window && window.location.pathname.startsWith('/how-it-works')
            ? 'linkItemActive'
            : 'hvr-icon-forward'
        }`,
        contactUsClass: `linkItem contactUsItem ${
          window && window.location.pathname.startsWith('/contact-us')
            ? 'linkItemActive'
            : 'hvr-icon-forward'
        }`,
        notificationsClass: `linkItem ${
          window && window.location.pathname.startsWith('/notifications')
            ? 'linkItemActive'
            : 'hvr-icon-forward'
        }`
      }
      this.setState(currentState)
    }
  }

  componentDidMount() {
    this.updateLinksClasses()
  }

  componentWillUnmount() {}

  render() {
    return (
      <StyledTgDrawerContent>
        <div
          className={`asideMenuContentWrapper ${
            this.props.permanent ? 'permanentWrapper' : ''
          } `}
        >
          <Link href="/home">
            <a
              className={this.state.homeClass}
              onClick={this.state.handleCloseDrawer}
            >
              <span className="iconWrapper">
                <FontAwesomeIcon
                  className="hvr-icon"
                  style={{ width: '21x', height: '21px' }}
                  icon={faHome}
                  size="2x"
                />
              </span>
              <span className="linkContent">Accueil</span>
              <FontAwesomeIcon
                className="hvr-icon"
                style={{ width: '20x', height: '20px' }}
                icon={faAngleRight}
                size="2x"
              />
            </a>
          </Link>
          {!this.props.user && (
            <a
              className="linkItem hvr-icon-forward"
              onClick={this.props.handleOpenLoginModalAction}
            >
              <span className="iconWrapper">
                <FontAwesomeIcon
                  className="hvr-icon"
                  style={{ width: '21x', height: '21px' }}
                  icon={faDoorOpen}
                  size="2x"
                />
              </span>
              <span className="linkContent">Me connecter</span>
              <FontAwesomeIcon
                className="hvr-icon"
                style={{ width: '20x', height: '20px' }}
                icon={faAngleRight}
                size="2x"
              />
            </a>
          )}
          {!this.props.user && (
            <a
              className="linkItem hvr-icon-forward "
              onClick={this.props.handleOpenRegisterModalAction}
            >
              <span className="iconWrapper">
                <FontAwesomeIcon
                  className="hvr-icon"
                  style={{ width: '21x', height: '21px' }}
                  icon={faSignInAlt}
                  size="2x"
                />
              </span>
              <span className="linkContent">M'inscrire</span>
              <FontAwesomeIcon
                className="hvr-icon"
                style={{ width: '20x', height: '20px' }}
                icon={faAngleRight}
                size="2x"
              />
            </a>
          )}
          {this.props.user && (
            <Link href="/profile">
              <a
                className={this.state.profileClass}
                onClick={this.state.handleCloseDrawer}
              >
                <span className="iconWrapper">
                  <FontAwesomeIcon
                    className="hvr-icon"
                    style={{ width: '21x', height: '21px' }}
                    icon={faUser}
                    size="2x"
                  />
                </span>
                <span className="linkContent">Mon profil</span>
                <FontAwesomeIcon
                  className="hvr-icon"
                  style={{ width: '20x', height: '20px' }}
                  icon={faAngleRight}
                  size="2x"
                />
              </a>
            </Link>
          )}
          {this.props.user && (
            <Link href="/dashboard">
              <a
                className={this.state.dashboardClass}
                onClick={this.state.handleCloseDrawer}
              >
                <span className="iconWrapper">
                  <FontAwesomeIcon
                    className="hvr-icon"
                    style={{ width: '21x', height: '21px' }}
                    icon={faTable}
                    size="2x"
                  />
                </span>
                <span className="linkContent">Tableau de bord</span>
                <FontAwesomeIcon
                  className="hvr-icon"
                  style={{ width: '20x', height: '20px' }}
                  icon={faAngleRight}
                  size="2x"
                />
              </a>
            </Link>
          )}
          {this.props.user && (
            <Link href="/list-piggys">
              <a className={this.state.piggybanksClass}>
                <span className="iconWrapper">
                  <FontAwesomeIcon
                    className="hvr-icon"
                    style={{ width: '21x', height: '21px' }}
                    icon={faPiggyBank}
                    size="2x"
                  />
                </span>
                <span className="linkContent">Mes tirelires</span>
                <FontAwesomeIcon
                  className="hvr-icon"
                  style={{ width: '20x', height: '20px' }}
                  icon={faAngleRight}
                  size="2x"
                />
              </a>
            </Link>
          )}
          <a
            className={this.state.howItWorksClass}
            href="/static/pdf/howto.pdf"
            target="_blank"
          >
            <span className="iconWrapper">
              <FontAwesomeIcon
                className="hvr-icon"
                style={{ width: '21x', height: '21px' }}
                icon={faQuestionCircle}
                size="2x"
              />
            </span>
            <span className="linkContent">Comment ça marche</span>
            <FontAwesomeIcon
              className="hvr-icon"
              style={{ width: '20x', height: '20px' }}
              icon={faAngleRight}
              size="2x"
            />
          </a>
          <a
            className={this.state.contactUsClass}
            onClick={this.props.handleContactUsAction}
          >
            <span className="iconWrapper">
              <FontAwesomeIcon
                className="hvr-icon"
                style={{ width: '21x', height: '21px' }}
                icon={faEnvelope}
                size="2x"
              />
            </span>
            <span className="linkContent">Nous contacter</span>
            <FontAwesomeIcon
              className="hvr-icon"
              style={{ width: '20x', height: '20px' }}
              icon={faAngleRight}
              size="2x"
            />
          </a>
          <Link href="/notifications">
            <a className={this.state.notificationsClass}>
              <span className="iconWrapper notificationWrapper">
                <FontAwesomeIcon
                  className="hvr-icon"
                  style={{ width: '21x', height: '21px' }}
                  icon={faBell}
                  size="2x"
                />
                {this.props?.user?.notificationsLength && (
                  <span className="notificationsLength">
                    {this.props?.user?.notificationsLength}
                  </span>
                )}
              </span>
              <span className="linkContent">Notifications</span>
              <FontAwesomeIcon
                className="hvr-icon"
                style={{ width: '20x', height: '20px' }}
                icon={faAngleRight}
                size="2x"
              />
            </a>
          </Link>
          {this.props.user && (
            <a
              className="linkItem hvr-icon-forward"
              onClick={this.props.handleLogoutAction}
            >
              {!this.props.logoutPending && (
                <span className="iconWrapper">
                  <FontAwesomeIcon
                    className="hvr-icon"
                    style={{ width: '21x', height: '21px' }}
                    icon={faPowerOff}
                    color="#ff0000"
                    size="2x"
                  />
                </span>
              )}
              {this.props.logoutPending && (
                <img
                  className="logoutLoading"
                  src="/static/img/loading-red.svg"
                  alt="loading"
                />
              )}
              <span className="linkContent">Me déconnecter</span>
              <FontAwesomeIcon
                className="hvr-icon"
                style={{ width: '20x', height: '20px' }}
                icon={faAngleRight}
                size="2x"
              />
            </a>
          )}
          <Link href="/create-piggy">
            <a className="createPiggy hvr-float">CRÉER UNE TIRELIRE</a>
          </Link>
        </div>
      </StyledTgDrawerContent>
    )
  }
}

TgDrawerContent.propTypes = {}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    ...ownProps
  }
}

const mapDispatchToProps = (dispatch, getState) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TgDrawerContent)
