import { actionTypes } from '../../actionTypes'
import { fetchCurrentUser as getCurrentUser } from '../../lib/utils'

// ACTIONS
export function fetchCurrentUser() {
  return async dispatch => {
    dispatch({ type: actionTypes.FETCHCURRENTUSER })
    try {
      const { isAuthenticated, user } = await getCurrentUser()
      if (!isAuthenticated) {
        throw new Error('User not authenticated')
      }
      dispatch({
        type: actionTypes.FETCHCURRENTUSERSUCCEEDED,
        payload: { user, isAuthenticated: true }
      })
    } catch (e) {
      console.error(`fetchCurrentUser - ${e.message}`)
      dispatch({
        type: actionTypes.FETCHCURRENTUSERFAILED,
        payload: e,
        error: true
      })
    }
  }
}

export function loadCurrentUser(user) {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCHCURRENTUSERSUCCEEDED,
      payload: { user, isAuthenticated: true }
    })
  }
}

export function unloadCurrentUser(user) {
  return dispatch => {
    dispatch({ type: actionTypes.USERLOGOUT })
  }
}
