const Sentry = require('@sentry/node')
const appConfig = require('./config').getConfig()

const ErrorLevel = {
  fatal: 'fatal',
  /** JSDoc */
  error: 'error',
  /** JSDoc */
  warning: 'warning',
  /** JSDoc */
  log: 'log',
  /** JSDoc */
  info: 'info',
  /** JSDoc */
  debug: 'debug',
  /** JSDoc */
  critical: 'critical'
}

const statuses = require('statuses')
/**
 * Common wrapper for error with code and data
 * @memberof module:errors
 */
class GenericError extends Error {
  constructor(code, message, data) {
    super(message)
    this.code = code
    this.data = data
    // ensure to store the name of the error
    // based on the subclass constructor in the instance
    this.name = this.constructor.name
  }
}

/**
 * An error that ensured that the provided error code
 * in HTTP compliant
 * @memberof module:errors
 */
class HttpError extends GenericError {
  constructor(code, message, data) {
    super(code, message, data)
    // The keys are strings
    if (!statuses.codes.includes(this.code)) {
      this.code = statuses('Internal Server Error')
    }
  }
}

/**
 * A Internal Server Error
 * @memberof module:errors
 */
class ServerError extends HttpError {
  constructor(args) {
    super(statuses('Internal Server Error'), args)
  }
}

class InvalidArguments extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), args)
  }
}

class MissingRequiredArguments extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Missing required arguments', args)
  }
}

class RequiredDocuments extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Missing required documents', args)
  }
}

class InvalidEmail extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Email is invalid', args)
  }
}

class InvalidPassword extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Password is invalid', args)
  }
}

class InvalidIdentifiers extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Identifiers are invalid', args)
  }
}

class InvalidVerificationCode extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Verification code is invalid', args)
  }
}

class InsecurePassword extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Password is not secure', args)
  }
}

class UserAlreadyCreated extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'User already created', args)
  }
}

class UserNotFound extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'User not found', args)
  }
}

/**
 * Error thrown when a user wanna access something that requires logged privileges.
 * @memberof module:errors
 */
class UnauthorizedUserException extends HttpError {
  constructor(args) {
    super(statuses('Unauthorized'), 'User not authenticated', args)
  }
}

class ForbiddenUserException extends HttpError {
  constructor(args) {
    super(
      statuses('Forbidden'),
      'User is not allowed to access this resource',
      args
    )
  }
}

/**
 * Error thrown when the login of a user fails
 * @memberof module:errors
 */
class LoginFailedException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), args)
  }
}
class PiggyCreationFailedException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), args)
  }
}

/**
 * Error thrown when a login is attempted w/ a invalid or
 * unknown email.
 * @memberof module:errors
 */
class NoSuchUserException extends LoginFailedException {
  constructor(args) {
    super('Invalid user', args)
  }
}

/**
 * Piggy exceptions
 * @memberof module:errors
 */
class AmountPerUserTooLowException extends PiggyCreationFailedException {
  constructor(args) {
    super('Amount per user is too low', args)
  }
}

class NotEnoughParticipants extends PiggyCreationFailedException {
  constructor(args) {
    super('Not Enough participants', args)
  }
}

class CreatorMustParticipateToPiggy extends PiggyCreationFailedException {
  constructor(args) {
    super('Creator must participate to Piggy', args)
  }
}

class DuplicateParticipantException extends PiggyCreationFailedException {
  constructor(args) {
    super('Duplicate participant in piggy', args)
  }
}

class StartDateTooCloseException extends PiggyCreationFailedException {
  constructor(args) {
    super('Start date is too close', args)
  }
}

class InvitationEmailException extends PiggyCreationFailedException {
  constructor(args) {
    super('Invalid invitation Email', args)
  }
}

class PiggyNotFound extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Piggy not found', args)
  }
}

class JoinPiggyException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Join piggy failed', args)
  }
}

class DeclinePiggyException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Decline piggy failed', args)
  }
}

class CancelPiggyException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Cancel piggy failed', args)
  }
}

class DeletePiggyException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Delete piggy failed', args)
  }
}

/**
 * PSP exceptions
 * @memberof module:errors
 */
class PspException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Psp error', args)
  }
}

class PspBadInputException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Psp error', args)
  }
}

class PspAccountCreationException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'PSP account creation failed', args)
  }
}

class PspAccountNotFoundException extends HttpError {
  constructor(args) {
    super(statuses('Not Found'), 'PSP account not found', args)
  }
}

class PspWalletCreationException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'PSP wallet creation failed', args)
  }
}

class PspAccountException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'PSP account error', args)
  }
}

class PspAccountStatusException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'PSP account status error', args)
  }
}

class PspKyc3RequiredException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'PSP KYC 3 required error', args)
  }
}

class PspProfileUpdateException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'PSP profile update failed', args)
  }
}

class PspProfileValidationException extends HttpError {
  constructor(args) {
    super(
      statuses('Bad Request'),
      'PSP profile update failed: account validation is in progress',
      args
    )
  }
}

class PspProfileValidatedException extends HttpError {
  constructor(args) {
    super(
      statuses('Bad Request'),
      'PSP profile update failed: account validation is in progress',
      args
    )
  }
}

class PspDocumentUpdateException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'PSP document update failed', args)
  }
}

class PspNotificationException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'PSP notification failed', args)
  }
}

class PspLastKYCValidationStatusException extends HttpError {
  constructor(args) {
    super(
      statuses('Bad Request'),
      'PSP could not get last KYC validation Status',
      args
    )
  }
}

class PspLastWalletException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'PSP could not get last Wallet', args)
  }
}

class MoneyInException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'PSP Money-In failed', args)
  }
}

class ContributionNotAllowedException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'User not allowed to contribute', args)
  }
}
class CancelContributionNotAllowedException extends HttpError {
  constructor(args) {
    super(
      statuses('Bad Request'),
      'User not allowed to cancel contribution',
      args
    )
  }
}
class MoneyOutException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'PSP Money-Out failed', args)
  }
}

class MoneyInInvalidAmountException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Money-In invalid amount', args)
  }
}

class MoneyOutInvalidAmountException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Money-Out invalid amount', args)
  }
}

class WalletBalanceExceeded extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Wallet balance limit exceeded', args)
  }
}

class MissingIbanArguments extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Missing iban required arguments', args)
  }
}

class IbanValidationException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Iban is not valid', args)
  }
}

class IbanRegistrationException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Iban registration error', args)
  }
}

class IbanAlreadyExists extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Iban already exists', args)
  }
}

class IbanWrongDataException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Iban registration error', args)
  }
}

class insufficientAccountBalance extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Insufficient account balance', args)
  }
}

class accountIsNotBalanced extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Account is not balanced', args)
  }
}

class NotificationException extends HttpError {
  constructor(args) {
    super(statuses('Bad Request'), 'Notification Error', args)
  }
}

class CreateNotificationException extends HttpError {
  constructor(args) {
    super(
      statuses('Bad Request'),
      'Error while creating the notification',
      args
    )
  }
}

function getHttpCode(code) {
  return statuses.codes.includes(code) ? code : 500
}

function reportError(error, ctx = null) {
  if (appConfig.env !== 'production') {
    console.error(JSON.stringify(error))
    return
  }

  if (!ctx) Sentry.captureException(error)
  else {
    const { req } = ctx
    Sentry.configureScope((scope) => {
      if (error.message) {
        scope.setFingerprint([error.message])
      }
      scope.setTag('api', true)
      scope.setExtra('url', req.url)
      scope.setExtra('method', req.method)
      scope.setExtra('headers', req.headers)
      scope.setExtra('params', req.params)
      scope.setExtra('query', req.query)

      // On server-side we take session cookie directly from request
      if (req.user) {
        scope.setUser({ id: req.user.id })
        scope.setExtra('email', req.user.email)
      }
    })
    return Sentry.captureException(error)
  }
}

function reportMessage(message, level = ErrorLevel.error) {
  if (appConfig.env !== 'production') {
    console && console.log(`${level} : ${message}`)
    return
  }
  Sentry.captureMessage(message, level)
}

function formatError(error, opts = {}) {
  try {
    delete error.request
    delete error.response
    delete error.toJSON
    delete error.config
    if (error instanceof Error) {
      let formatted = {}
      formatted = Object.assign(formatted, error)
      formatted.message = error.stack
      return formatted
    }
    return error
  } catch (err) {
    logger.error(`${logPrefix} formatError:cant format error`)
    return new Error('Generic error')
  }
}

module.exports = {
  formatError,
  getHttpCode,
  GenericError,
  HttpError,
  ServerError,
  InvalidArguments,
  InvalidEmail,
  InvalidPassword,
  InsecurePassword,
  MissingRequiredArguments,
  RequiredDocuments,
  UserAlreadyCreated,
  InvalidVerificationCode,
  UserNotFound,
  InvalidIdentifiers,
  UnauthorizedUserException,
  ForbiddenUserException,
  NotEnoughParticipants,
  AmountPerUserTooLowException,
  StartDateTooCloseException,
  InvitationEmailException,
  reportError,
  reportMessage,
  CreatorMustParticipateToPiggy,
  NoSuchUserException,
  DuplicateParticipantException,
  PiggyNotFound,
  JoinPiggyException,
  DeclinePiggyException,
  CancelPiggyException,
  DeletePiggyException,
  PspException,
  PspBadInputException,
  PspAccountException,
  PspAccountStatusException,
  PspKyc3RequiredException,
  PspAccountCreationException,
  PspAccountNotFoundException,
  PspWalletCreationException,
  PspProfileUpdateException,
  PspProfileValidationException,
  PspProfileValidatedException,
  PspDocumentUpdateException,
  PspNotificationException,
  PspLastKYCValidationStatusException,
  PspLastWalletException,
  MoneyInException,
  MoneyInInvalidAmountException,
  WalletBalanceExceeded,
  MoneyOutInvalidAmountException,
  MoneyOutException,
  MissingIbanArguments,
  IbanValidationException,
  IbanAlreadyExists,
  IbanRegistrationException,
  IbanWrongDataException,
  insufficientAccountBalance,
  accountIsNotBalanced,
  CreateNotificationException,
  NotificationException,
  ContributionNotAllowedException,
  CancelContributionNotAllowedException
}
