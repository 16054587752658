import fetchPonyfill from 'fetch-ponyfill'
import { generateRandomId, getQueryString } from './utils'

import { reportError } from '../api/services/error'
import * as ConfigService from '../api/services/config'

const { fetch } = fetchPonyfill()
const appConfig = ConfigService.getConfig()

export const NotificationCategories = {
  user: 'user',
  piggy: 'piggy'
}

export const NotificationSubcategories = {
  profile: 'profile',
  invitation: 'invitation',
  acceptedInvitation: 'acceptedInvitation',
  cancel: 'cancel',
  delete: 'delete',
  start: 'start',
  contribution: 'contribution',
  cancelContribution: 'cancelContribution',
  distribution: 'distribution',
  expiredInvitation: 'expiredInvitation',
  stop: 'stop'
}

export const NotificationLevels = {
  critical: 'critical',
  info: 'info'
}

export async function getNotifications(req, skip = 0, limit = 10) {
  try {
    const endpoint = req ? appConfig.url : window.location.origin
    const qs = getQueryString({
      skip,
      limit
    })
    const url = new URL(`${endpoint}/api/notification/list?${qs}`)
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-tgth-trace-id': generateRandomId()
    }

    if (req?.headers?.cookie) {
      headers.cookie = req.headers.cookie
    }

    const response = await fetch(url, {
      method: 'GET',
      mode: 'no-cors',
      headers,
      credentials: 'same-origin'
    })

    const body = await response.json()

    if (response.ok) {
      return body
    } else {
      console.error(
        `Lib Notification - getNotifications failed - Response: ${response}`
      )
      throw new Error(body.name)
    }
  } catch (error) {
    reportError(error)
    console.error('getNotifications - Error:', error)
  }
}

export async function clearNotification(notificationId, skip, limit, req) {
  try {
    const endpoint = req ? appConfig.url : window.location.origin
    const qs = getQueryString({
      skip,
      limit
    })
    const url = new URL(
      `${endpoint}/api/notification/clear/${notificationId}?${qs}`
    )
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-tgth-trace-id': generateRandomId()
    }

    if (req?.headers?.cookie) {
      headers.cookie = req.headers.cookie
    }

    const response = await fetch(url, {
      method: 'GET',
      mode: 'no-cors',
      headers,
      credentials: 'same-origin'
    })

    const body = await response.json()

    if (response.ok) {
      return body
    } else {
      console.error(
        `Lib Notification - clearNotification failed - Response: ${response}`
      )
      throw new Error(body.name)
    }
  } catch (error) {
    reportError(error)
    console.error('clearNotification - Error:', error)
  }
}

// Mark notification as read for both categories: piggy and user
// Call it with piggyId argument for piggy or no argument for user
export async function markNotificationAsRead(piggyId, req) {
  try {
    const endpoint = req ? appConfig.url : window.location.origin

    const url = new URL(`${endpoint}/api/notification/mark-as-read`)
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-tgth-trace-id': generateRandomId()
    }

    if (req?.headers?.cookie) {
      headers.cookie = req.headers.cookie
    }

    const response = await fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      headers,
      credentials: 'same-origin',
      body: JSON.stringify({
        piggyId
      })
    })

    const body = await response.json()

    if (response.ok) {
      return body
    } else {
      console.error(
        `Lib Notification - markNotificationAsRead failed - Response: ${response}`
      )
      throw new Error(body.name)
    }
  } catch (error) {
    reportError(error)
    console.error('markNotificationAsRead - Error:', error)
  }
}
