import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimes,
  faPiggyBank,
  faUser,
  faUserPlus,
  faPlus,
  faWallet,
  faUndoAlt,
  faBell,
  faPlay,
  faHandPaper,
  faHourglassEnd
} from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import {
  NotificationCategories,
  NotificationSubcategories,
  NotificationLevels
} from '../../lib/notification'

const StyledTgNotifications = styled.section`
  .notificationsWrapper {
    background: #fff;
  }

  .notificationItem {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin: 2px 0;
  }

  .notificationLink {
    cursor: pointer;
    min-height: 50px;
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    border-radius: 13px;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: stretch;
    padding: 5px 0;
  }

  .notificationClose {
    display: inline-flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 40px;
  }

  .notificationMessage {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: gotham-rounded-book, sans-serif;
    color: #0facf3;
    flex-grow: 1;
    padding: 15px;
    background-color: rgba(15, 172, 243, 0.13);
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;

    &.critical {
      background-color: rgba(255, 92, 92, 0.1);
      color: #ff5c5c;
    }

    &.info {
      background-color: #ff5c5c;
    }
  }

  .critical .notificationMessage {
    background-color: rgba(255, 92, 92, 0.1);
    color: #ff5c5c;
  }

  .unread {
    font-weight: bold;
  }

  .notificationCategory {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-family: gotham-rounded-book, sans-serif;
    letter-spacing: 1px;
    color: #ffffff;
    width: 100px;
    text-transform: uppercase;
    background-color: #0facf3;
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;
  }

  .noNotification {
    text-align: center;
    font-size: 13px;
    color: #0facf3;
    padding: 20px;
  }
`

const StyledRenderNotificationCategory = styled.section`
  .notificationType {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
    width: 52px;
    min-width: 52px;
    height: 100%;

    &.critical {
      background: #ff5c5c;
    }

    &.info {
      background: #0facf3;
    }
  }
`

class TgNotifications extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  renderNotificationSubcategory = (
    subcategory,
    level = NotificationLevels.info
  ) => {
    let icon, bgClass

    switch (subcategory) {
      case NotificationSubcategories.profile:
        icon = faUser
        break
      case NotificationSubcategories.invitation:
        icon = faPlus
        break
      case NotificationSubcategories.acceptedInvitation:
        icon = faUserPlus
        break
      case NotificationSubcategories.cancel:
      case NotificationSubcategories.stop:
        icon = faHandPaper
        break
      case NotificationSubcategories.delete:
        icon = faTimes
        break
      case NotificationSubcategories.start:
        icon = faPlay
        break
      case NotificationSubcategories.contribution:
        icon = faPiggyBank
        break
      case NotificationSubcategories.distribution:
        icon = faWallet
        break
      case NotificationSubcategories.cancelContribution:
        icon = faUndoAlt
        break
      case NotificationSubcategories.expiredInvitation:
        icon = faHourglassEnd
        break
      default:
        icon = faBell
    }

    switch (level) {
      case NotificationLevels.critical:
        bgClass = 'critical'
        break
      default:
        bgClass = 'info'
    }

    return (
      <StyledRenderNotificationCategory>
        <div className={`notificationType ${bgClass}`}>
          <FontAwesomeIcon
            style={{ width: '21px', height: '21px' }}
            icon={icon}
            color="#fff"
            size="2x"
          />
        </div>
      </StyledRenderNotificationCategory>
    )
  }

  renderNotifications(notifications) {
    let notificationsToDisplay
    if (notifications.length > 0) {
      // Display just 4 notifications when previewed in headers or dashboard
      if (this.props.preview && notifications.length > 4) {
        notificationsToDisplay = notifications.slice(0, 4)
      } else {
        notificationsToDisplay = notifications
      }
      return notificationsToDisplay.map((notification) => {
        return (
          <div key={notification.id} className="notificationItem">
            <a
              className="notificationLink"
              href={notification.actionUrl ? notification.actionUrl : '#'}
            >
              {this.renderNotificationSubcategory(
                notification.subcategory,
                notification.level
              )}
              <div
                className={
                  notification.isRead
                    ? 'notificationMessage'
                    : 'notificationMessage unread'
                }
              >
                {notification.description}
              </div>
            </a>
            {notification.category === NotificationCategories.piggy && (
              <a
                className="notificationClose"
                onClick={() => this.props.onClearNotification(notification.id)}
              >
                <FontAwesomeIcon
                  style={{ width: '15px', height: '15px' }}
                  icon={faTimes}
                  color="#d8d8d8"
                  size="2x"
                />
              </a>
            )}
          </div>
        )
      })
    } else {
      return <div className="noNotification">Aucune notification</div>
    }
  }

  render() {
    return (
      <StyledTgNotifications>
        <div className="notificationsWrapper">
          {this.props.notifications &&
            this.renderNotifications(this.props.notifications)}
        </div>
      </StyledTgNotifications>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    ...ownProps
  }
}

const mapDispatchToProps = (dispatch, getState) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TgNotifications)
