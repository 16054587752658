import { actionTypes } from '../../actionTypes'
import { getNotifications } from '../../lib/notification'

// ACTIONS
export function fetchNotifications(req, skip = 0, limit = 10) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FETCHNOTIFICATION })
    try {
      const { notifications, unreadCounter } = await getNotifications(
        req,
        skip,
        limit
      )

      dispatch({
        type: actionTypes.FETCHNOTIFICATIONSUCCEEDED,
        payload: { notifications, unreadCounter }
      })
    } catch (e) {
      console.error(`Action - fetchNotifications - ${e.message}`)
      dispatch({
        type: actionTypes.FETCHNOTIFICATIONFAILED,
        payload: e,
        error: true
      })
    }
  }
}

export function loadNotifications(notifications, unreadCounter) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCHNOTIFICATIONSUCCEEDED,
      payload: { notifications, unreadCounter }
    })
  }
}

export function updateUnreadNotificationCounter(unreadCounter) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCHNOTIFICATIONSUCCEEDED,
      payload: { unreadCounter }
    })
  }
}
