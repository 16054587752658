import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'

const StyledTgPrefetcher = styled.section`
  ul {
    display: none;
  }
`

class TgPrefetcher extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fullScreen: false,
      email: '',
      generatePending: false
    }
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <StyledTgPrefetcher>
        <ul>
          {this.props?.routes.map((route, idx) => (
            <li key={idx}>
              <Link href={route}>
                <a>{route}</a>
              </Link>
            </li>
          ))}
        </ul>
      </StyledTgPrefetcher>
    )
  }
}

export default TgPrefetcher
